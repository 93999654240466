import React from 'react';
import Typed from 'react-typed';

import michaelScott from './assets/images/911-michael-scott.gif';
import michaelAvatar from './assets/images/michael-scott.webp';
import dwightAvatar from './assets/images/dwight.jpg';
import ashtonAvatar from './assets/images/ashton.webp';

import fold from './assets/images/fold.svg';
import tick from './assets/images/tick.svg';

import './App.css';

function App() {
    return (
        <div className="site">
            <header className="site-header">
                <div>
                    <span className={'site-header__notification-text'}>no </span>
                    <span className='site-header__notification-strike'>
                    <Typed
                        strings={[
                            '@here',
                            '@everyone',
                            '@channel'
                        ]}
                        className={'site-header__notification-text'}
                        loop
                        backSpeed={60}
                        backDelay={500}
                        typeSpeed={80}
                    />
                    </span>
                    <p className={'site-header__subtitle'}>please don't alert everyone, unless it is <span className={'site-header__subtitle-strong'}>absolutely</span> necessary</p>
                </div>
                <img src={michaelScott} alt="michael scott" className={'site-header__image'}/>
            </header>
            <div className={'site-content'}>
                <p className={'site-content__title'}>Imagine going to a fast-food restaurant, grabbing every single customer and employee and saying that there is a pickle missing from your cheeseburger... 🤦</p>
                <h2><img src={fold} alt={'Red fold symbol'}/> Don't do this</h2>
                <div className={'site-content__row'}>
                    <div className={'site-content__messages'}>
                        <div className={'site-content__card'}>
                            <div className={'site-content__card-message'}>
                                <img src={michaelAvatar} alt={'Michael Scott avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Michael</span> <span className={'site-content__card-message-time'}>3:14 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@here</span> I cannot login to the portal guys</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={dwightAvatar} alt={'Michael Scott avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Dwight</span> <span className={'site-content__card-message-time'}>6:23 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@channel</span> does anyone know where is stationary?</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={ashtonAvatar} alt={'Kevin avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}><s>Ashton</s> Kevin</span> <span className={'site-content__card-message-time'}>8:23 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@everyone</span> I think there might be a problem with my email account</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'site-content__rules'}>
                        <p>Note that Michael, Dwight and Kevin have notified a whole range of people of relatively insignificant issues.</p>
                        <p>If your issue is not of high importance (to a wider range of people) you do not have to notify <b>every single person</b> in the channel about it. Every notification that your co-workers get causes them to get distracted and context-switch and trust me <b>this is not great for productivity</b>.</p>
                        <p>Your work chat channels likely contain a varied range of people - both "customers" and "supporters". If you use <i>@here</i>, <i>@channel</i>, <i>@everyone</i> or <i>other @'s</i> you send a notification to a wider group of people.</p>
                        <h4>Before you send @ - ask yourself</h4>
                        <ul>
                            <li>Is everyone who's going to get notified about it should be notified?</li>
                            <li>Do people who get notified care about it</li>
                            <li>Is the issue significant enough to notify these people?</li>
                        </ul>
                    </div>
                </div>
                <h2><img src={tick} alt={'Red fold symbol'}/> Instead, do this</h2>
                <div className={'site-content__row'}>
                    <div className={'site-content__messages'}>
                        <div className={'site-content__card'}>
                            <div className={'site-content__card-message'}>
                                <img src={michaelAvatar} alt={'Michael Scott avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Michael</span> <span className={'site-content__card-message-time'}>3:14 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}>I cannot login to the portal guys</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={dwightAvatar} alt={'Dwight avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Dwight</span> <span className={'site-content__card-message-time'}>5:23 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@payments-team</span> I believe there's an incident with your payment provider</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={dwightAvatar} alt={'Dwight avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Dwight</span> <span className={'site-content__card-message-time'}>6:23 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@here</span> I believe there's an incident affecting all of our services</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={michaelAvatar} alt={'Michael Scott avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}>Michael</span> <span className={'site-content__card-message-time'}>7:14 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@channel</span> we got attacked by ransomware, switch off your machines now</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'site-content__card-message'}>
                                <img src={ashtonAvatar} alt={'Kevin avatar'}/>
                                <div>
                                    <div>
                                        <span className={'site-content__card-message-author'}><s>Ashton</s> Kevin</span> <span className={'site-content__card-message-time'}>8:59 AM</span>
                                    </div>
                                    <div>
                                        <p className={'site-content__card-message-content'}><span className={'site-content__card-message-tagging'}>@everyone</span> there's an earthquake happening right now where our office is</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'site-content__rules'}>
                        <p>Note that Michael, Dwight and Kevin assessed the significance of the issue and based on their assessment they notified the right people.</p>
                        <p>It's not about not using <b>@'s</b> - it is about using them wisely. Not every single person needs to be notified of everything, if your issue needs someone's attention make sure you choose as a narrow range of people to notify as possible.</p>
                        <p>Sometimes tagging only the relevant team will be much better than tagging every single person in the channel or organisation. Use common sense to decide whether you should put <i>@</i> in your message. If you decide you should, then use your common sense to decide whom you should tag!</p>
                        <h4>If you decide to send @ - ask yourself</h4>
                        <ul>
                            <li>See if the selected @ is relevant and proportionate to your issue</li>
                            <li>Consider different @ levels will have different outcomes (e.g. @some-team will only ping active team members, while @channel might ping everyone regardless of their notification settings)</li>
                            <li>@everyone - <b>don't even think about it</b></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={'site-footer'}>
                <p>This is kinda only half serious (kinda 👀) so please don't get <a href="https://www.youtube.com/watch?v=xzpndHtdl9A">mad</a> at the person who sent you here.</p>
                <p>This said, if you get this sites URL as a response to your message - it means you might have used <b>@</b> incorrectly.</p>
                <p>Based on wonderful <a href={'https://nohello.net'}>nohello.net</a>. Avatars and GIF taken from <a href={'https://en.wikipedia.org/wiki/The_Office_(American_TV_series)'}>The Office (US)</a>.</p>
            </div>
        </div>
    );
}

export default App;
